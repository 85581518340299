import React from "react"
import styled from "styled-components"
import { darken } from "polished"
import { graphql, useStaticQuery } from "gatsby"

import {
  Color,
  Type,
  Grid,
  Col,
  AlignLeft,
  AlignCenter,
  ButtonA,
  Media,
  Wrapper,
  Margin,
} from "../components/utilities"
import { Section, Banner } from "../components/elements"
import { Layout, Divider, HeaderBorder } from "../components/layouts/Layout"

const ServiceTitle = styled.div`
  text-align: center;
  display: block;
  color: ${Color.green};
  background: ${Color.white};
  border: 2px solid ${Color.lite_green};
  font-size: 1.4rem;
  padding: 1.5rem;
  transition: all 0.5s ease;

  ${Media.below.tablet`
    padding: 1rem;
    font-size: 1.2rem;
  `}
`

const ServiceText = styled.div`
  padding: 1.5rem;
  text-align: center;
  color: ${Color.grey};

  ul,
  li {
    font-family: ${Type.text};
    font-size: 16px !important;
  }

  li {
    margin-bottom: 0.5rem;
    line-height: 150%;
  }

  p {
    margin-bottom: 0;
  }

  a {
    font-size: 16px;
    color: ${Color.green};
    font-weight: bold;
  }
`

const SprigSection = styled(Section)`
  background-color: #efefef;
  p {
    color: ${darken(0.2, Color.grey)};
  }
`

const ServiceCardContainer = styled.div`
  &:hover {
    ${ServiceTitle} {
      background: ${Color.lite_green};
      color: ${Color.white};
    }
  }
`

const ApplyGrid = styled(Grid)`
  p {
    font-size: 0.9rem;
    line-height: 150%;
  }

  ${Media.below.tablet`
    grid-template-columns: 1fr;
  `}
`

const ServiceCard = ({ title, children }) => (
  <ServiceCardContainer>
    <ServiceTitle>{title}</ServiceTitle>
    <ServiceText>{children}</ServiceText>
  </ServiceCardContainer>
)

const OurServiceSection = ({ coopImg }) => (
  <Section>
    <h1>Our Services</h1>
    <HeaderBorder maxWidth="400px" />
    <Grid modifiers={["thirds", "gap"]}>
      <ServiceCard title="Checking Accounts">
        <strong>"Free Checking" / Senior Saver Checking</strong>
        <ul>
          <AlignLeft>
            <li>
              New or existing member with qualifying: Direct Deposit, or any
              lending product (Installment Loan, Overdraft Line of Credit, VISA
              Credit Card)
            </li>
            <li>NO minimum balance requirement</li>
            <li>Dividends on average balances over $1000</li>
            <li>
              Surcharge free ATMs at thousands of credit union ATMs across the
              US
            </li>
            <li>Debit card or ATM card at no cost</li>
          </AlignLeft>
        </ul>

        <a href="/checking-accounts.pdf">Click here for options</a>
      </ServiceCard>
      <ServiceCard title="Shared Branch">
        <p>
          Shared Branch is a great service that allows members to visit
          participating Credit Unions and perform transactions on their SAFCU
          accounts.
        </p>
        <br />
        <a href="https://sharedbranching.org/" target="_blank">
          <img src={coopImg} />
        </a>
      </ServiceCard>
      <ServiceCard title="Home Loans">
        <p>
          Home Mortgage Purchasing and Refinancing Available. Please complete
          the{" "}
          <a
            href="https://www.mycumortgagehomeloans.com/default.asp?siteId=C48C0F21-1AF1-4228-A7F8-374F5D2A1F6B"
            target="_blank"
          >
            Mortgage Application
          </a>{" "}
          via the link. For more information, call SAFCU and ask for Pat at{" "}
          <a href="tel:4198823525">(419) 882-3525</a> to see what we can do for
          you!
        </p>
      </ServiceCard>
      <ServiceCard title="Home Equity Loans">
        <p>
          Home improvements, wedding expenses, college tuition, dream vacation,
          any large purchase or refinance your current equity loan from another
          financial institution for a lower rate, as low as 4.50% A.P.R. for up
          to a 180 month term.
          <br />
          <br />
          Call for details. <a href="tel:4198823525">(419) 882-3525</a>.
        </p>
      </ServiceCard>
      <ServiceCard title="Credit and Loans">
        <p>
          At SAFCU, your money is federally insured up to $250,000. So you can
          count on your money being safe with us! Payday Loans, Holliday Loans,
          Boat Loans, Car Loans, Student Loans, Home Equity Loans, and Home
          Improvement Loans can be used for expenditures that can help you and
          your family to get the financial assistance it needs. Perhaps just to
          bridge the gap between pay periods or to send a child to school, loans
          can help make the financial burdens a little easier to manage.
        </p>
        <br />
        <a href="/safcu-loan-rates.pdf" target="_blank">
          Click here to see our current loan rates
        </a>
      </ServiceCard>
      <ServiceCard title="Auto Loans">
        <AlignLeft>
          <ul>
            <li>
              Extremely low Rates!{" "}
              <a href="/safcu-loan-rates.pdf" target="_blank">
                See our rate sheet for current rates!
              </a>
            </li>
            <li>Avoid high financing fees</li>
            <li>
              <a href="/ach-deposit-form.pdf" target="_blank">
                Direct Deposit is available
              </a>
            </li>
            <li>
              <a href="/direct-payment-auth.pdf" target="_blank">
                Discounts available with Automatic payment
              </a>
            </li>
            <li>Make payments at any Shared Branch location</li>
            <li>Transfer your payment to your loan on Virtual Branch</li>
            <li>Credit Life, Disability, and Gap insurance available to you</li>
            <li>
              Do business with your local Credit Union and support your
              community
            </li>
            <li>Keep your money in Sylvania</li>
            <li>
              Applications available online{" "}
              <a href="https://sylvaniaareafcu.com">sylvaniaareafcu.com</a>
            </li>
          </ul>
        </AlignLeft>
      </ServiceCard>
    </Grid>
  </Section>
)

const ImageColumn = styled.div`
  height: 100%;
  background-size: auto;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  ${props =>
    props.image &&
    `
  background-image: url(${props.image});
  `}
`

const SpecialImg = styled.div`
  img {
    ${Media.below.tablet`
        display: none;
      `}
  }
`

const IndexPage = ({ data }) => {
  const {
    banner,
    newYear,
    sallie,
    visa,
    mastercard,
    coop,
    balance,
    sprig,
    googleplay,
    appstore,
  } = data
  return (
    <Layout title="Home Page">
      <Banner background={banner.childImageSharp.fluid.src}>
        <p>sylvania ohio's own community</p>
        <span>credit union</span>
      </Banner>
      <Section>
        <h1>Specials</h1>
        <HeaderBorder maxWidth="400px" />
        <br />
        <Grid modifiers={["gap"]}>
          <Col>
            <h2>
              New year, new car! <br />
              Auto loan special
              <br />
              rates as low as 2.49% apr*!
            </h2>
            <HeaderBorder maxWidth="400px" />
            <small>
              *APR = Annual Percentage Rate. All loans are subject to credit
              approval. Rate based on credit worthiness; all members may not
              qualify. Other terms and conditions may apply. Please speak to a
              SAFCU loan specialist for details and/or apply online!
            </small>
          </Col>
          <ImageColumn image={newYear.childImageSharp.fluid.src} />
          <AlignCenter>
            <SpecialImg>
              <img src={balance.childImageSharp.fluid.src} />
            </SpecialImg>
          </AlignCenter>
          <Col>
            <h2>
              Get Your Financial
              <br />
              Fresh Start!
              <br />
            </h2>
            <HeaderBorder maxWidth="400px" />
            Seize the moment and get your financial fresh start! Renew your
            financial well-being with the help of our new balance transfer rate.
            2.99% APR for 24 months when you tranfer an existing balance to our
            credit union credit card between February 1, 2021 and April 30,
            2021.
          </Col>
          <Col>
            <h2>
              Credit Union Ballet
              <br />
            </h2>
            <HeaderBorder maxWidth="400px" />
            As SAFCU continues to grow, it becomes more important to make sure
            that you - as a member- have the chance to vote for the Directors
            that will serve you and the entire membership. Board decisions
            become more complex each year. Download the ballet for more
            information.
          </Col>
          <Col>
            <Margin modifiers={["mt4"]} />
            <ButtonA
              target="_blank"
              href="/2021-BOD-ballot.pdf"
              modifiers={["primaryOutline"]}
            >
              Download Ballot
            </ButtonA>
          </Col>
        </Grid>
      </Section>
      <Divider />
      <Wrapper>
        <OurServiceSection coopImg={coop.childImageSharp.fluid.src} />
      </Wrapper>
      <Divider />
      <SprigSection>
        <Grid modifiers={["thirds2x1", "gap"]}>
          <Col>
            <h2>
              SPRIG Mobile – The Ultimate in <br />
              Mobile Banking
            </h2>
            <HeaderBorder maxWidth="400px" />
            <p>
              Sprig is the ultimate virtual credit union wallet-with P2P
              payments to anyone plus all your accounts from all your credit
              unions together in one spot. It's as convenient as your laptop,
              smartphone, or tablet and accessible anytime, day or night. Access
              your accounts, make MOBILE deposits, transfer between accounts,
              pay loans from one institution to another, make P2P payments from
              virtually anywhere. Download the FREE SPRIG app from your app
              store and follow the enrollment instructions, or logon to the
              website from your computer at{" "}
              <a href="https://www.getsprig.com">www.getsprig.com</a>
            </p>
          </Col>
          <Col>
            <AlignCenter>
              <img
                style={{ width: "255px" }}
                src={sprig.childImageSharp.fluid.src}
              />
              <div>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=org.coopfs.sprig"
                >
                  <img src={googleplay.childImageSharp.fluid.src} />
                </a>
                <a
                  target="_blank"
                  href="https://apps.apple.com/us/app/sprig-by-co-op/id860607829?ign-mpt=uo%3D4"
                >
                  <img src={appstore.childImageSharp.fluid.src} />
                </a>
              </div>
            </AlignCenter>
          </Col>
        </Grid>
      </SprigSection>
      <Divider />
      <Section>
        <Wrapper>
          <AlignCenter>
            <ApplyGrid modifiers={["thirds", "gap"]}>
              <Col>
                <a
                  href="https://www.salliemae.com/landing/student-loans/?MPID=1000001470&dtd_cell=SMLRSOPTRPSVN010661"
                  target="_blank"
                >
                  <img src={sallie.childImageSharp.fluid.src} />
                </a>
                <p>
                  Apply for a student loan today! Click the image to the left
                  and get started on an education with the help of Salle Mae
                  student loans.
                </p>
              </Col>

              <Col>
                <img src={visa.childImageSharp.fluid.src} />
                <br />
                <br />
                <p>
                  Stop in today for an application for a Visa Credit card and
                  get the security that so many others have with Visa Verified
                  Services.
                </p>
              </Col>
              <Col>
                <a
                  href="https://secure2.es.arcot.com/vpas/1342/i18n/en_US/index.html"
                  target="_blank"
                >
                  <img src={mastercard.childImageSharp.fluid.src} />
                </a>

                <p>
                  Secure your online purchases even further. Sign up for the
                  Mastercard Secure Code / Verified by Visa and make sure that
                  the only online purchases are made by the card holder.
                </p>
                <a
                  href="https://secure2.es.arcot.com/vpas/1342/i18n/en_US/index.html"
                  target="_blank"
                >
                  Click here to sign up
                </a>
              </Col>
            </ApplyGrid>
          </AlignCenter>
        </Wrapper>
      </Section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query InternalPageQuery {
    banner: file(relativePath: { eq: "sylvania.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          src
        }
      }
    }
    newYear: file(relativePath: { eq: "new-year.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          src
        }
      }
    }
    balance: file(relativePath: { eq: "balance-promo-sm.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          src
        }
      }
    }
    sprig: file(relativePath: { eq: "sprig.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          src
        }
      }
    }
    googleplay: file(relativePath: { eq: "google-play.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          src
        }
      }
    }
    appstore: file(relativePath: { eq: "appstore.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          src
        }
      }
    }
    sallie: file(relativePath: { eq: "salliemae.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          src
        }
      }
    }
    visa: file(relativePath: { eq: "visa.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          src
        }
      }
    }
    mastercard: file(relativePath: { eq: "mastercard.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          src
        }
      }
    }
    coop: file(relativePath: { eq: "coop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 150) {
          src
        }
      }
    }
  }
`
